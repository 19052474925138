//Only general css
.page-container {
    max-width: 1920px;
}
.content-container {
    max-width: 1800px;
}

html, body {
    background-color: $background-color;
    // overflow-x: hidden;
}

@mixin font-montserrat-medium ($size, $minSize, $color) {
    @include Montserrat-Medium;
    @include text-responsive($size, $minSize);
    color: $color !important;
}

.text-color {
    @each $name, $val in $text-colors-array {
        &_#{$name} {
            color: $val;
        }
    }
}

.background-color {
    @each $name, $val in $background-colors-array {
        &_#{$name} {
            background-color: $val;
        }
    }
}

.text-size {
    &_50 {
        @include text-responsive (50pt, 50px);
    }
    @each $name, $val in $text-sizes-array {
        &_#{$name} {
            @include text-responsive (nth($val, 1), nth($val, 2));
        }
    }
}

.text {
    &_montserrat-medium {
        @include Montserrat-Medium;
    }
    &_montserrat-light {
        @include Montserrat-Light;
    }
    &_montserrat-extrabold {
        @include Montserrat-ExtraBold;
    }
    &_montserrat-medium-italic {
        @include Montserrat-MediumItalic;
    }
    &_montserrat-regular {
        @include Montserrat-Regular;
    }
    &_montserrat-semibold {
        @include Montserrat-SemiBold;
    }
    &_montserrat-bold {
        @include Montserrat-Bold;
    }
    &_myriadpro-regular {
        @include MyriadPro-Regular;
    }
    &_montserrat-black {
        @include Montserrat-Black;
    }
}

.overflow-visible {
    overflow: visible !important;
}

.white-space {
    white-space: nowrap;
    &--reponsive {
        @include media-breakpoint-down (md) {
            white-space: normal;
        }
    }
}
.text-fully-centered {
    display: grid;
    place-items: center;
}

.underlined {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 0; left: 50%;
        transform: translateX(-50%);
        width: 90%; height: 2px;
        background-color: $main-color;
    }
    &--white {
        &::after {
            background-color: white;
        }
    }
}

.main-gradient {
    background: rgb(49,51,118);
    background: -moz-linear-gradient(90deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
    background: linear-gradient(90deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#313376",endColorstr="#73b35a",GradientType=1);
    &_backwards {
        background: rgb(49,51,118);
        background: -moz-linear-gradient(270deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
        background: -webkit-linear-gradient(270deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
        background: linear-gradient(270deg, rgba(49,51,118,1) 0%, rgba(115,179,90,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#313376",endColorstr="#73b35a",GradientType=1);
    }
}

.k-icon-fix-pos {
    svg {
        top: 0px !important;
    }
}

.modal-closer {
    cursor: pointer;
}

.place-items-center {
    place-items: center;
}

%bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
}

%absolute-vertically {
    position: absolute;
    top: 50%;
    bottom: unset;
    transform: translateY(-50%);
}

%absolute-vertically-bottom {
    position: absolute;
    top: unset;
    bottom: 0%;
    transform: translateY(-50%);
}

%absolute-fully-centered {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}

.remove_link_colour {
    a:hover, a:focus, a:active {
        color: white !important;
    }
  }

#header-menu {
    @include media-breakpoint-down (md) {
        display: none;
    }
}

.certifications_carousel-pagination {
    @extend %absolute-fully-centered;
    top: 20px; bottom: 0;
}
.certifications_carousel_bullet {
    margin-left: 2px;
}

.about-us_carousel-pagination, .certifications_carousel-pagination, .certifications_carousel-pagination  {
    .swiper-pagination-bullet-active {
        background-color: $secondary-darker;
    }
}
.about-us_carousel-bullet {
    margin-left: 5px;
    background-color: rgba($secondary-darker, 0.5);
}
.swiper-container-free-mode>.swiper-wrapper{transition-timing-function : linear;}
.swiper-wrapper{-webkit-transition-timing-function:linear!important; -o-transition-timing-function:linear!important; transition-timing-function:linear!important; }