//Only components as k-header, k-carousel, k-product, etc
.horizontal-menu-layout {
    // left by default
    position: relative;
    &_icon {
        left: 0;
        z-index: 5;
        border: 1px solid;
        border-radius: 100%; 
        display: inline-block;
        @extend %absolute-vertically;
    }
    &_body {
        z-index: 3;
        padding-left: 55px;
        @extend %absolute-vertically;
    }
}

.rounded-icon {
    border: 2px solid;
    position: relative;
    border-radius: 100%;
    width: 30px; height: 30px;
    .z-icon {
        @extend %absolute-fully-centered;
    }
    &--medium {
        width: 40px; height: 40px;
    }
    &--flat {
        border: none;
    }
}

.primary-button {
    border-radius: 5px;
    padding: 5px 15px;
    &_smaller {
        border-radius: 20px;
        padding: 3px 11px;
    }
}

.connected-table {
    padding-left: 4rem;
    $spacing: 1.25rem;
    min-height: 600px;
    
    @include media-breakpoint-down (md) {
        min-height: 1000px !important;
        height: unset !important;
    }
    @include media-breakpoint-down (mxl) {
        min-height: unset;
        height: 450px;
    }

    display: flex;
    flex-direction: column;

    li:first-child {
        flex: 1;
        &::after {
            width: 5px;
            z-index: 100;
            background-color: $tertiary-color;
            transform: translateX(-35px) translateY(-50%);
        }
    }
    li:nth-child(2) {
        &::after {
            height: calc(100%);
        }
    }
    li {
        $separation: -35px;

        flex: 3;
        align-items: center;

        list-style: none;
        position: relative;
        // margin-bottom: $spacing;
        &::before, &::after {
            left: 0;
            content: "";
            position: absolute;
            background-color: $main-color;
        }
        &::before {
            top: 50%;
            width: 21px; height: 2px;
            transform: translateX($separation) translateY(-50%);
        }
        &::after {
            top: 0%;
            width: 2px; height: calc(100%);
            transform: translateX(-35px) translateY( calc(-50%) );
        }
    }

    &--separator {
        &::before {
            display: none;
        }
    }
}

.list-none {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.header {
    z-index: 100;
    &_body {
        width: 800px;
    }
    &_menu, &_menu_responsive {
        list-style: none;
        display: inline-block;
        padding-left: 0 !important;
        margin-bottom: 0 !important;
    }
    &_menu {
        z-index: 10;
        background-color: $main-color;
        li:last-child, & {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        li {
            float: left;
            padding: 0 1rem;
            transition: all 0.3s ease-in-out;
            &:hover {
                filter: brightness(90%);
                background-color: $main-color;
            }
            @include media-breakpoint-down (md) {
                padding: 5px 1rem;
            }
        }
        &_show-xs {
            display: none;
        }
        &_responsive {
            width: 100%;
            position: absolute;
            top: 25px; left: 0;
            background-color: $tertiary-color;
            padding: 40px 3rem 20px 6rem !important;
        }
        
        @include media-breakpoint-down (md) {
            &_hide-xs {
                display: none;
            }
            &_show-xs {
                display: block;
            }
        }
    }

    .horizontal-menu-layout, .horizontal-menu-layout_icon {
        height: 65px;
    }

    .horizontal-menu-layout_icon {
        width: 65px;
        border-color: $secondary-color;
    }
    &--white {
        .horizontal-menu-layout_icon {
            border-color: white !important;
        }
    }
    &_bars {
        z-index: 101;
        display: flex;
        min-width: 30px;
        min-height: 19px;
        position: relative;
        align-items: center;
        transition: all 0.3s ease-in-out;
        @include media-breakpoint-down (sm) {
            width: 30px;
            height: auto;
        }
        &_wrapper {
            z-index: 101;
            position: fixed;
            top: 0; right: 0;
            cursor: pointer;
            padding: 10px 5px;
            align-self: center;
            display: inline-block;
            transition: all 0.3s ease-in-out;
        }
        &--bar {
            $separation: 6px;
            width: 100%;
            height: 2px;
            transition: all 0.3s ease-in-out;
            background-color: white;
            &::before, &::after {
                content: '';
                position: absolute;
                height: 2px;
                background-color: white;
                // transition: all 0.1s ease-in-out;
            }
            &::before {
                transform: translateY(-$separation);
                transition: all 0.3s ease-in-out;
                width: 100%;
            }
            &::after {
                transform: translateY($separation);
                transition: all 0.3s ease-in-out;
                width: 100%;
            }
        }
        &--cross {
            .header_bars--bar {
                transform: rotate(45deg);
            }
            .header_bars--bar::after {
                transform: translate(0, 0);
            }
            .header_bars--bar::before {
                transform: translateY(0) rotate(90deg);
            }
        }
    }
}

.social-media {
    z-index: 90;
    position: fixed;
    right: 0; top: 50%;
    transform: translateX(-50%) translateY(-50%);
    .rounded-icon {
        border: 0px;
        margin-top: 5px;
    }
}

.blog {
    &_button {
        padding: 5px 15px;
        border-radius: 20px;
    }
    &_image {
        height: 350px;
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%; height: 100%;
            object-position: center center;
        }
        @include media-breakpoint-down (md) {
            height: 250px;
        }
        @include media-breakpoint-up (xxl) {
            height: 500px;
        }
    }
}

.header-badge {
    height: 18px;
}

.footer {
    &_copyright {
        border-left: 2px solid white;
        @include media-breakpoint-down (md) {
            border: none;
            text-align: center;
        }
    }
    &_logo {
        width: 347px; height: 51px;
    }
    &_privacy {
        border-radius: 15px;
        border: 1px solid $gray;
    }
}

.library-form {
    z-index: 1000;
    top: 0; left: 0;
    position: fixed;
    place-items: center;
    justify-content: center;
    width: 100vw; height: 100vh;
    background-color: rgba($main-color, 0.8);
    &_icon {
        width: 100px; height: 100px;
    }
    &_bob {
        transform: translateY(-25%) translateX(15%) !important;   
    }
    &_close {
        top: 0; right: 25px;
        transform: translateY(-50%);
        position: absolute;
    }
}

.demo-form {
    z-index: 1000;
    width: 700px;
    max-width: 700px;
    max-height: 90vh;
    overflow-y: auto;
    position: fixed;
    top: 65px; right: 0;
    background-color: #E5E5E5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @include media-breakpoint-down (md) {
        width: 95vw;
    }
    &--library {
        position: relative;
        top: unset; right: unset;
    }
    &--contact {
        position: absolute;
        top: 50%; left: 50%;
        max-height: 95vh;
        transform: translate(-50%, -50%);
    }
    &_header {
        height: 200px;
    }
    &_close {
        top: 0; right: 15px;
        transform: translateY(-200%);
        position: absolute;
    }
    &_title {
        max-width: 350px;
    }
    &_left-bar {
        min-width: 220px;
    }
    &_bob {
        top: 0; left: 0;
        transform: translateY(-50%) translateX(10%);
        position: absolute;
        width: 190px; height: 190px;
        @include media-breakpoint-down (md) {
            display: none;
        }
        &--flat {
            left: 50%;
            transform: translateY(-50%) translateX(-300%);
        }
    }
    &_select {
        input {
            width: 250px !important;
            border-radius: 0 !important;
            border-color: $main-color !important;
            @include font-montserrat-medium (11pt, 13px, $main-color);
            &, &::placeholder {
                @include font-montserrat-medium (11pt, 13px, $main-color);
            }
        }
        .el-icon-arrow-up:before {
            color: $main-color !important;
        }
    }
    &_date {
        input {
            // margin-left: 1rem !important;
            width: 250px;
            padding-right: 0 !important;
            color: $main-color !important;
            background-color: transparent !important;
            @include font-montserrat-medium (15pt, 15px, $main-color);
        }
        .el-icon-time:before {
            color: $main-color !important;
            @include text-responsive (19pt, 13px);
        }
    }
    &_bottom {
        height: 150px;
        clip-path: url(#clip-path);
        background-color: $tertiary-color;
    }
    &_body {
        max-width: 600px;
    }
}

.form-field {
    input {
        text-align: center;
        border: none !important;
        background-color: transparent !important;
        @include font-montserrat-medium (11pt, 13px, $secondary-color);
        border-bottom: 1px solid rgba($secondary-color, 0.3) !important;
        &, &::placeholder {
            @include font-montserrat-medium (11pt, 13px, rgba($secondary-color, 0.5));
        }
    }
}

.contact-carousel {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 50%; right: 50%;
        background-color: white;
        height: 60%; width: 1px;
        transform: translate(-50%, -50%);
        @include media-breakpoint-down (md) {
            display: none;
        }
    }
}

.contact-overlay {
    z-index: 10;
    top: 0; left: 0;
    position: absolute;
    width: 100vw; height: 100vh;
    // background-color: rgba($main-color, 0.8);
}

.modal {
    &_overlay {
        display: none;
        z-index: 100;
        top: 0; left: 0;
        position: fixed;
        width: 100vw; height: 100vh;
        // background-color: rgba($main-color, 0.8);
    }
    &_wrapper {
        min-height: 100vh;
        min-width: 100vw;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    &_shape {
        display: flex;
        min-height: 280px;

        @include media-breakpoint-down (sm) {
            overflow: hidden;
            flex-direction: column;
        }
        
        &--rounded {
            // height: 300px;
            clip-path: url(#clip-path2);
            @include media-breakpoint-down (sm) {
                min-height: 300px;
                clip-path: unset;
            }
        }
        
    }
    &_infrastructure {
        &_content {
            // overflow: hidden;
            width: 100%; height: 100%;
            background-color: $tertiary-color;
        }
        &_ben {
            top: 0; right: 0;
            position: absolute;
            background-color: white;
            width: 302px; height: 300px;
            border-bottom-left-radius: 100%;
            img {
                width: 200px;
                transform: translate(75px, 25px);
            }
            @include media-breakpoint-down (xl) {
                height: 250px;
            }
            @include media-breakpoint-down (md) {
                display: none;
            }
        }
        &_description {
            width: 70%;
            @include media-breakpoint-down (md) {
                width: 100%;
            }
        }
    }
    &_solutions {
        &_content {
            width: 80%;
            @include media-breakpoint-down (sm) {
                width: 100%;
            }
        }
        &_ben {
            width: 20%;
            @include media-breakpoint-down (sm) {
                width: 100%;
                height: 200px;
                text-align: center;
                img {
                    height: 200px;
                }
                // display: none;
            }
        }
    }
    &_body {
        z-index: 100;
        width: 1054px;
        max-width: 95vw;
        transform: translateX(-50%);
        @extend %absolute-fully-centered;
        top: 10vh;
        @include media-breakpoint-down (md) {
            top: 10vh !important;
        }
        @include media-breakpoint-down (mxl) {
            top: 2vh;
        }

        &--center {
            transform: translateX(-50%) translateY(-50%) !important;
            top: 50% !important;
        }

        &--infrastructure {
            &::before {
                transform: translateX(-50%) translateY(40px) !important
            }
        }
        
        &::before {
            z-index: -1;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
            width: 80%;
            height: 200px;
            background-color: #2E3078;
            -webkit-transform: translateX(-50%) translateY(201px);
            transform: translateX(-50%) translateY(200px);
            clip-path: url(#clip-path2);
            @include media-breakpoint-down (xl) {
                transform: translateX(-50%) translateY(180px);
            }
            @include media-breakpoint-down (sm) {
                bottom: 80px;
                width: 90%; height: 65px;
                transform: translateX(-50%) translateY(140px);
            }
        }
    }
    &_close {
        right: 0; top: 0;
        position: absolute;
        transform: translateX(110%);
        @include media-breakpoint-down (sm) {
            transform: translateX(-100%) translateY(- 120%);
        }
    }

    &_bottom {
        z-index: -1;
        position: absolute;
        bottom: 0; left: 0;
        width: 100%; height: 300px;
        transform: translateY(152px);
        clip-path: url(#clip-path2);
        @include media-breakpoint-down (sm) {
            height: 51px;
            bottom: 50px;
            transform: translateY(100px);
        }
    }
}

::-webkit-scrollbar-track
{
  border-radius: 5px;
  background-color: transparent;
}

::-webkit-scrollbar
{
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background-color: $secondary-color;
}