//Only specific css for the pages (this file has the max priority)
.home {
    &_header {
        top: 10px;
        z-index: 100;
        position: sticky;
        margin-top: -75px;
    }
    &_banner {
        z-index: 97;
        position: relative;
        width: 100vw; height: 100vh;
        &::after {
            content: "";
            z-index: 10;
            top: 0; left: 0;
            position: absolute;
            width: 100%; height: 100%;
            background-color: rgba(black, 0.6);
        }
    }
    &_badge {
        z-index: 99;
        top: 0; left: 0;
        position: absolute;
        width: 100%; height: 70px;
        @include media-breakpoint-down (md) {
            display: flex;
            height: fit-content;
            flex-direction: column;
            padding: 1rem 5px;
        }
        &_pagination {
            .swiper-pagination-bullet-active {
                background-color: $main-color !important;
            }
        }
        &_bullet {
            margin-left: 5px;
            background-color: white;
        }
    }
    &_benchmark {
        z-index: 98;
        @extend %absolute-fully-centered; 
        img {
            width: 730px;
            @include media-breakpoint-down (md) {
                width: 95vw;
            }
        }
    }
    &_mouse {
        z-index: 98;
        position: absolute;
        letter-spacing: 5px;
        bottom: 0px; left: 50%;
        transform: translate(-50%, -25%);
        @media only screen and (max-width: 1600px) and (min-width: 900px) {
            transform: translate(-50%, -100%);
        }
        img {
            width: 22px; height: 36px;
        }
        &_arrow {
            position: absolute;
            bottom: 0; left: 25%;
            // transform: translateX(-50%);
        }
    }
    &_carousel {
        width: 100%;
        &_badge {
            left: 95%;
            z-index: -1;
            min-width: 413px;
            width: 100%; height: 80px;
            @extend %absolute-vertically;
            box-shadow: 5px 5px 2.5px 0px rgba(0,0,0,0.75);

            @include media-breakpoint-down (lg) {
                min-width: 250px;
                width: unset;
            }

            @include media-breakpoint-down (md) {
                width: 100vw;
                height: 60px;
                top: 0; left: 0;
                box-shadow: none;
                position: relative;
            }
        }
        &_icon {
            padding: 3rem;
            display: table-cell;
            border-radius: 100%; 
            vertical-align: middle;
            width: 350px; height: 350px;
            @include media-breakpoint-down (lg) {
                width: 250px; height: 250px;
            }
            @include media-breakpoint-down (md) {
                padding: 1rem;
                width: 250px; height: 250px;
            }
        }
        &_text {
            max-width: 500px;
            height: 140px;
        }
        &_pagination, .blogs-page_pagination {
            .swiper-pagination-bullet-active {
                background-color: white;
            }
        }
        &_bullet, .blogs-page_bullet {
            margin-left: 5px;
            background-color: rgba(white, 0.5);
        }
    }
    &_bob {
        z-index: 10;
        position: fixed;
        bottom: 0px; right: 0;
        width: 200px; height: 200px;
        @include media-breakpoint-down (md) {
            width: 150px; height: 150px;
        }
    }
    &_mid-video {
        height: 100%;
        object-fit: cover;
    }
    &_main-video {
        height: 100%;
        object-fit: cover;
        position: relative;
        
    }
    &_blogs {
        &_pagination {
            .swiper-pagination-bullet-active {
                background-color: $secondary-color;
            }
        }
        &_bullet {
            margin-left: 5px;
            background-color: rgba($secondary-color, 1);
        }
        &_header-subtitle {
            // top: 0; right: 0;
            // position: absolute;
            // transform: translateY(-100%) translateX(-20px);
        }
        &_header-icon {
            z-index: 10;
            position: absolute;
            top: 50%; right: 0;
            transform: translateY(-50%);
        }
    }

    &_verticales {
        @include media-breakpoint-up (md) {
            max-width: 0;
            width: 100vw;
            z-index: 99;
            top: 0; left: 0;
            position: absolute;
            height: calc(100vh);
            align-items: stretch;
            margin: 0 !important;
            overflow: hidden; 
        }
    }
}

.bi {
    &_subtitle {
        width: 100%;
        position: absolute;
        bottom: 0; left: 50%;
        transform: translateY(130%) translateX(-50%);
        @include media-breakpoint-down (xs) {
            width: unset;
            bottom: 20%;
        }
        &_icon {
            width: 111px; height: 82px;
        }
    }
    &_question {
        @include title-responsive (300pt, 100px);
    }
    &_listing {
        display: flex;
        text-align: center;
        border-radius: 100%;
        place-items: center;
        place-content: center;
        width: 48px; height: 48px;
        border: 1px solid $tertiary-color;
    }
    &_image {
        width: 100%; height: 600px;
        @include media-breakpoint-down (md) {
            height: 300px;
        }
    }
}

.about-us {
    &_image {
        object-fit: contain;
        max-width: 500px; max-height: 500px;
        @include media-breakpoint-down (sm) {
            max-width: 300px; max-height: 300px;
        }
    }
    &_certifications {
        border: 1px solid rgba($tertiary-color, 0.6);
        &_image {
            width: 200px; height: 140px;
        }
        &_title {
            position: absolute;
            top: 0; left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.blogs-page {
    &_carousel {
        &_image {
            object-fit: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    &_showcase {
        height: 500px;
    }

    &_paginator-index {
        position: absolute;
        top: 100%; left: 50%;
        transform: translateX(-50%);
    }

    &_index {
        z-index: 10;
        top: 0; left: 0;
        position: absolute;
        transform: translateY(-100%);
        @include media-breakpoint-down (md) {
            transform: translateY(50%);
        }
    }
    
    &_button {
        padding: 5px 15px;
        border-radius: 10px;
    }

    &_paginator {

    }
}

.library {
    &_item {
        &_image {
            width: 150px; height: 90px;
            @include media-breakpoint-down (sm) {
                width: auto;
                height: auto;
            }
        }
    }
    &_search {
        top: 100%; left: 50%;
        position: absolute;
        border-radius: 15px;
        width: 550px; height: 40px;
        transform: translateX(-50%) translateY(50%);

        @include media-breakpoint-down (md) {
            width: 100%;
        }

        input {
            padding: 10px 15px !important;
            border-radius: 20px !important;
            border: 1px solid rgba(gray, 0.4) !important;
        }

        input:focus {
            outline: none;
        }
        &_icon {
            top: 50%; right: 0%;
            position: absolute;
            transform: translateX(-50%) translateY(-48%); 
        }
    }
}

.solutions {
    &_section {
        // width: 100%;
        min-height: 1080px;
        // @include media-breakpoint-down (md) {
        //     background-image: url(build/images/solutions-bg.png) !important;
        // }
    }
    &_main-item {
        top: 20px; left: 50%;
        position: absolute;
        transform: translateX(-50%);
        &--bottom {
            top: unset; bottom: 20px;
        }
        @media only screen and (max-width: 1000px) {
            position: relative;
        }
    }
    &_brain {
        width: 90px; height: 76px;
    }
    &_graph {
        width: 165px; height: 42px;
    }
    &_world {
        &_container {
            position: absolute;
            top: 50%; left: 50%;
            width: 50vw; height: 50vh;
            transform: translate(-50%, -50%);
            @include media-breakpoint-down (md) {
                transform: unset;
                position: relative;
                top: unset; left: unset;
                width: unset; height: unset;

            }
        }
    }
    &_inteligence {
        width: 54px; height: 54px;
        &_container {
            position: absolute;
            top: 0px; left: 90px;
            @media only screen and (max-width: 1820px) {
                top: -50px; left: -50px;
            }
            @media only screen and (max-width: 1300px) {
                top: -50px; left: -50px;
            }
            @media only screen and (max-width: 1000px) {
                top: unset; left: unset;
                position: relative;
            }
        }
    }
    &_benchmap {
        width: 71; height: 60px;
        &_container {
            position: absolute;
            top: 50%; left: -50px;
            transform: translateY(-50%);
            @media only screen and (max-width: 1820px) {
                top: 50%; left: -150px;
            }
            @media only screen and (max-width: 1300px) {
                top: 50%; left: -150px;
            }
            @media only screen and (max-width: 1000px) {
                top: unset; left: unset;
                transform: unset;
                position: relative;
            }
        }
    }
    &_security {
        width: 51; height: 64px;
        &_container {
            position: absolute;
            bottom: 30px; left: 90px;
            @media only screen and (max-width: 1820px) {
                bottom: -50px; left: -75px;
            }
            @media only screen and (max-width: 1300px) {
                bottom: -50px; left: -75px;
            }
            @media only screen and (max-width: 1000px) {
                bottom: unset; left: unset;
                position: relative;
            }
        }
    }
    &_big-data {
        width: 55; height: 56px;
        &_container {
            position: absolute;
            top: 50px; right: 80px;
            @media only screen and (max-width: 1820px) {
                top: -25px; right: -100px;
            }
            @media only screen and (max-width: 1300px) {
                top: -25px; right: -100px;
            }
            @media only screen and (max-width: 1000px) {
                top: unset; right: unset;
                position: relative;
            }
        }
        &_fix { // Desborde tras actualizaciones kk del cliente, odio a los clientes
            min-width: 85px;
            @include media-breakpoint-down (md) {
                min-width: unset;
            }
        }
        &_fix-text {
            @include media-breakpoint-up (xl)  {
                font-size: 20px !important;
            }
        }
    }
    &_legal {
        width: 64.7px; height: 51px;
        &_container {
            position: absolute;
            bottom: 50px; right: 0;
            @media only screen and (max-width: 1820px) {
                bottom: -0px; right: -150px;
            }
            @media only screen and (max-width: 1300px) {
                bottom: -0px; right: -150px;
            }
            @media only screen and (max-width: 1000px) {
                bottom: unset; right: unset;
                position: relative;
            }
        }
    }
    &_stars {
        z-index: 100;
        top: 0; left: 0;
        position: absolute;
        width: 100%; height: 100%;
    }
    &_left-bar {
        &::after {
            content: "";
            left: 0; top: 50%;
            position: absolute;
            width: 100vw; height: 8px;
            background-color: $main-color;
            transform: translateX(-100%) translateY(-50%);
            @include media-breakpoint-down (md) {
                display: none;
            }
        }
    }
    &_right-bottom-bar {
        &::after {
            content: "";
            position: absolute;
            left: 0; bottom: 0%;
            width: 100vw; height: 4px;
            background-color: $main-color;
            @include media-breakpoint-down (md) {
                display: none;
            }
            
        }
    }

    &_modal {
        position: relative;
        width: 100vw; height: 100vh;
        background-color: rgba($main-color, 0.6);
    }
} 

.infrastructure {
    &_modal-bg, &_modal-border {
        min-height: 600px;
        padding: 2rem 6rem;
        clip-path: url(#clip-path3);
        @include media-breakpoint-down (md) {
            min-height: 600px !important;
            height: unset !important;
        }
        @include media-breakpoint-down (mxl) {
            min-height: unset;
            height: auto;
        }
        @include media-breakpoint-down (sm) {
            clip-path: unset;
        }
    }
    &_notch1 {
        width: 70%;
        @include media-breakpoint-down (md) {
            width: 100%;
        }
    }
    &_notch2 {
        width: 80%;
        @include media-breakpoint-down (md) {
            width: 100%;
        }
    }
    &_notch3 {
        width: 90%;
        @include media-breakpoint-down (md) {
            width: 100%;
        }
    }
    &_left-flag, &_right-flag {
        height: 150px;
    }
    &_left-flag {
        clip-path: url(#clip-left);
    }
    &_right-flag {
        clip-path: url(#clip-right);
    }
    &_modal-border-box {
        position: absolute;
        top: 0; left: 0;
        z-index: 100;
        border: solid 2px #fff;
        width: 100%; height: 100%;
        border-top-left-radius: 50% 90%;
        border-top-right-radius: 50% 90%;
        border-bottom-left-radius: 50% 90%;
        border-bottom-right-radius: 50% 90%;
        border-color: transparent #fff transparent #fff;
        @include media-breakpoint-down (md) {
            height: 500px;
        }
        @include media-breakpoint-down (sm) {
            display: none;
        }
        &--circle {
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            height: 680px; width: 680px;
            border-top-left-radius: 100% 100%;
            border-top-right-radius: 100% 100%;
            border-bottom-left-radius: 100% 100%;
            border-bottom-right-radius: 100% 100%;
            border-color: #fff #fff #fff transparent;

            @include media-breakpoint-down (mxl){
                height: 580px; width: 580px;
            }
            @include media-breakpoint-down (xl){
                height: 440px; width: 440px;
            }
        }
    }

    &_circled-bg {
        height: 605px; width: 605px;
        @include media-breakpoint-down (mxl) {
            height: 520px; width: 520px;
        }
        @include media-breakpoint-down (xl) {
            height: 400px; width: 400px;
        }
        clip-path: circle(50% at 50% 50%);
        @include media-breakpoint-down (sm) {
            height: 700px;
            padding: 10px 0;
            clip-path: unset;
        }
        &_content {
            @extend %absolute-fully-centered;
        }
    }

    &_list {
        min-width: 270px;
    }

    &_images {
        &_redes-agregacion {
            width: 265px; height: 73px;
            @include media-breakpoint-down (xl) {
                width: 205px; height: auto;
            }
        }
        &_transporte {
            width: 190px; height: 116px;
            @include media-breakpoint-down (xl) {
                width: 130px; height: auto;
            }
        }
        &_ethernet {
            width: 188px; height: 51px;
            @include media-breakpoint-down (xl) {
                width: 128px; height: auto;
            }
        }
        &_software-defined {
            width: 130px; height: 59px;
            @include media-breakpoint-down (xl) {
                width: 90px; height: auto;
            }
        }
        &_software-defined2 {
            width: 214px; height: 44px;
            @include media-breakpoint-down (xl) {
                width: 154px; height: auto;
            }
        }
        &_wifi {
            width: 91px; height: 62px;
            @include media-breakpoint-down (xl) {
                width: 71px; height: auto;
            }
        }
        &_maps {
            width: 78px; height: 80px;
            @include media-breakpoint-down (xl) {
                width: 58px; height: auto;
            }
        }
        &_navegation {
            width: 80px; height: 86px;
            @include media-breakpoint-down (xl) {
                width: 60px; height: auto;
            }
        }
        &_localization {
            width: 48px; height: 82px;
            @include media-breakpoint-down (xl) {
                width: 28px; height: auto;
            }
        }
        &_links {
            width: 77px; height: 77px;
            @include media-breakpoint-down (xl) {
                width: 57px; height: auto;
            }
        }
        &_grid {
            width: 58px; height: 58px;
        }
        &_infrastructure {
            width: 95px; height: 95px;
        }
        &_bandwidth {
            width: 65px; height: 65px;
        }
        &_bussiness {
            width: 214px; height: 44px;
        }
        &_access {
            width: 219px; height: 54px;
            @include media-breakpoint-down (xl) {
                width: 189px; height: auto;
            }
        }
        &_firewall {
            width: 204px; height: 58px;
            @include media-breakpoint-down (xl) {
                width: 174px; height: auto;
            }
        }
        &_security2 {
            width: 148px; height: 87px;
            @include media-breakpoint-down (xl) {
                width: 118px; height: auto;
            }
        }
        &_control {
            width: 48px; height: 81px;
            @include media-breakpoint-down (xl) {
                width: 38px; height: auto;
            }
        }
        &_monitoring-bulk {
            width: 291px; height: 422px;
            @include media-breakpoint-down (mxl) {
                width: 230px; height: auto;
            }
            @include media-breakpoint-down (xl) {
                width: 160px; height: auto;
            }
        }
        &_cloud-bulk {
            width: 341px; height: 414px;
            @include media-breakpoint-down (mxl) {
                width: 250px; height: auto;
            }
            @include media-breakpoint-down (xl) {
                width: 170px; height: auto;
            }
        }
    }

    &_world {
        &_container {
            position: absolute;
            top: 50%; left: 50%;
            width: 50vw; height: 50vh;
            min-width: 900px; min-height: 800px;
            transform: translate(-50%, -40%);
            @include media-breakpoint-down (xl) {
                top: 41%;
                width: 80vw;
                transform: translate(-50%, -30%);
            }
            @include media-breakpoint-down (md) {
                min-width: unset; min-height: unset;
                transform: unset;
                position: relative;
                top: unset; left: unset;
                width: unset; height: unset;

            }
        }
    }


    &_sd-wan {
        width: 54px; height: 62px;
    }
    &_cloud {
        width: 90px; height: 53px;
    }
    &_continuity {
        width: 64px; height: 64px;
    }
    &_security {
        width: 51px; height: 62px;
    }
    &_monitoring {
        width: 58px; height: 57px;
    }
    &_network {
        width: 53px; height: 53px;
    }
    
}

#settlementsSwiperPagination {
    bottom: -5%;
    left: 0;
    width: 100%;
    height: 50px;
    .swiper-pagination-bullet {
        margin-left: 10px;
    }
    .swiper-pagination-bullet-active {
        background-color: $main-color;
    }    
}

.security_modal_contents, .network_modal_contents, .continuity_modal_contents {
    @include media-breakpoint-down (md) {
        height: auto !important;
    }
    @include media-breakpoint-down (mxl) {
        height: 400px;
    }
    @include media-breakpoint-down (xl) {
        height: 330px;
    }
}

.sd-wan_modal_contents {
    @include media-breakpoint-down (md) {
        height: auto !important;
    }
    @include media-breakpoint-down (mxl) {
        height: 420px;
    }
    @include media-breakpoint-down (xl) {
        height: 320px;
    }
}
.cloud_modal_contents, .monitoring_modal_contents, .evaluation_modal_contents {
    @include media-breakpoint-down (md) {
        height: auto !important;
    }
    @include media-breakpoint-down (mxl) {
        height: 500px;
    }
    @include media-breakpoint-down (xl) {
        height: 380px;
    }
}