$main-color: #64AF57;
$secondary-color: #262626;
$tertiary-color: #2E3078;
$tertiary-darker-color: #06195F;
$tertiary-lighter-color: #353F7B;
$gray: #999999;
$darker: #666666;
$secondary-darker: #1A1A1A;

// $text-color: #4D4D4D;
// $text-color-darker: #505052;
// $board-color: #B2B2B2;
// $form-text: #8C8C8C;

$background-color: white;

$text-colors-array: (
    'white': white,
    'black': black,
    'main': $main-color,
    'gray': $gray,
    'darker': $darker,
    'secondary': $secondary-color,
    'tertiary': $tertiary-color,
    );
    
    $background-colors-array: (
    'main': $main-color,
    'white': white,
    'tertiary': $tertiary-color,
    'tertiary-darker': $tertiary-darker-color,
    'tertiary-lighter': $tertiary-lighter-color,
    'secondary': $secondary-color,
    'secondary-darker': $secondary-darker,
    'main-opacity': rgba($main-color, 0.7),
);

// max, min
$text-sizes-array: (
    '7': (
        7pt, 7pt,
    ),
    '9': (
        9pt, 11px,
    ),
    '12': (
        10pt, 12px,
    ),
    '15': (
        11pt, 14px,
    ),
    '17': (
        12pt, 13px,
    ),
    '18': (
        13pt, 14px,
    ),
    '20': (
        16px, 14px,
    ),
    '22': (
        15pt, 16px,
    ), 
    '25': (
        16.5pt, 16px,
    ),
    '27': (
        17.5pt, 17px,
    ),
    '30': (
        30px, 18px,
    ),
    '31': (
        20pt, 19px,
    ),
    '35': (
        22pt, 21px,
    ),
    '38': (
        23pt, 22px,
    ),
    '50': (
        27pt, 26px,
    ),
    '55': (
        28pt, 26px,
    ),
    '60': (
        30pt, 31px,
    ),
    '70': (
        40pt, 32px,
    ),
    '85': (
        50pt, 37px,
    ),
    '90': (
        55pt, 45px,
    ),
    'insane': (
        300pt, 80px,
    ),
);