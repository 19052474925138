$grid-breakpoints: (
  xs: 0,
  xsm: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  mxl: 1500px,
  xxl: 1921px, 
);

$spacer: 2rem;
$spacers: (
	6: ($spacer * 4.5),
	7: ($spacer * 6),
	8: ($spacer * 7.5),
	9: ($spacer * 9),
	10: ($spacer * 10.5),
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
