.from-right {
    transform: translateX(150%);
    &--social-media {
        transform: translateX(150%) translateY(-50%);
    }
}


.animated {
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.animation {
    transition: all ease-in-out 0.8s;
}

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-5px);
    }
}
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}
.bounce {
    animation-name: bounce;
}