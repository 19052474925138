@font-face {
    font-family: 'Montserrat-Medium';
    src: url('Montserrat-Medium.eot');
    src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff'),
        url('Montserrat-Medium.ttf') format('truetype'),
        url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
}

@mixin Montserrat-Medium {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('Montserrat-Light.eot');
    src: url('Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Light.woff2') format('woff2'),
        url('Montserrat-Light.woff') format('woff'),
        url('Montserrat-Light.ttf') format('truetype'),
        url('Montserrat-Light.svg#Montserrat-Light') format('svg');
}

@mixin Montserrat-Light {
    font-family: 'Montserrat-Light';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('Montserrat-ExtraBold.eot');
    src: url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff'),
        url('Montserrat-ExtraBold.ttf') format('truetype'),
        url('Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
}

@mixin Montserrat-ExtraBold {
    font-family: 'Montserrat-ExtraBold';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url('Montserrat-MediumItalic.eot');
    src: url('Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-MediumItalic.woff2') format('woff2'),
        url('Montserrat-MediumItalic.woff') format('woff'),
        url('Montserrat-MediumItalic.ttf') format('truetype'),
        url('Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
}

@mixin Montserrat-MediumItalic {
    font-family: 'Montserrat-MediumItalic';
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('Montserrat-Regular.eot');
    src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype'),
        url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
}

@mixin Montserrat-Regular {
    font-family: 'Montserrat-Regular';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('Montserrat-SemiBold.eot');
    src: url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff'),
        url('Montserrat-SemiBold.ttf') format('truetype'),
        url('Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
}

@mixin Montserrat-SemiBold {
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MyriadPro-Regular';
    src: url('MyriadPro-Regular.eot');
    src: url('MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('MyriadPro-Regular.woff2') format('woff2'),
        url('MyriadPro-Regular.woff') format('woff'),
        url('MyriadPro-Regular.ttf') format('truetype'),
        url('MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
}

@mixin MyriadPro-Regular {
    font-family: 'MyriadPro-Regular';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff'),
        url('Montserrat-Bold.ttf') format('truetype'),
        url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
}

@mixin Montserrat-Bold {
    font-family: 'Montserrat-Bold';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Black';
    src: url('Montserrat-Black.eot');
    src: url('Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Black.woff2') format('woff2'),
        url('Montserrat-Black.woff') format('woff'),
        url('Montserrat-Black.ttf') format('truetype'),
        url('Montserrat-Black.svg#Montserrat-Black') format('svg');
}

@mixin Montserrat-Black {
    font-family: 'Montserrat-Black';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}